import { Button, ButtonGroup, Checkbox, Grid, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { CheckboxField as CheckboxFieldType } from '../../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormDataContext';
import { FormActionType } from '../context/form-state-reducer';

type Props = {
  field: CheckboxFieldType;
};

const CheckboxField: FC<any> = ({ field }: Props) => {
  const { dispatch } = useFormData();

  useEffect(() => {
    function validateField(value: boolean): string {
      return '';
    }

    const error = validateField(field.value);
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field.value, dispatch, field.id]);

  function handleInputChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.checked,
      },
    });
  }
  return (
    <Grid container direction={'row'} alignItems="center" spacing={1} item xs={12}>
      {!field.config.buttonsList && (
        <Grid item xs={1}>
          <Checkbox
            disabled={field.config.readonly}
            color="primary"
            checked={field.value}
            onChange={handleInputChange}
          />
        </Grid>
      )}
      <Grid item xs={field.config.buttonsList ? 6 : 11}>
        <Typography variant="body1" style={{ wordBreak: 'break-word' }}>
          {field.config.title}
        </Typography>
      </Grid>
      {field.config.buttonsList && <ButtonList field={field} />}
    </Grid>
  );
};
const ButtonList = ({ field }: Props) => {
  const { dispatch } = useFormData();
  return (
    <Grid item xs={6}>
      <ButtonGroup className="w-full">
        <Button
          className="w-full"
          variant="contained"
          color={field.value ? 'primary' : 'default'}
          disabled={field.config.readonly}
          style={{ minHeight: '100%', opacity: field.value ? '100%' : '50%', fontSize: '0.6em' }}
          onClick={() =>
            dispatch({
              type: FormActionType.SET_FIELD_VALUE,
              payload: {
                id: field.id,
                value: true,
              },
            })
          }
        >
          <span className="break-all">{field.config.trueLabel}</span>
        </Button>
        <Button
          variant="contained"
          className="w-full"
          color={!field.value ? 'primary' : 'default'}
          disabled={field.config.readonly}
          style={{ minHeight: '100%', opacity: !field.value ? '100%' : '50%', fontSize: '0.6em' }}
          onClick={() =>
            dispatch({
              type: FormActionType.SET_FIELD_VALUE,
              payload: {
                id: field.id,
                value: false,
              },
            })
          }
        >
          <span className="break-all">{field.config.falseLabel}</span>
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

export default CheckboxField;
