import { FC, useEffect } from 'react';
import { useFormData } from '../context/FormDataContext';
import { FormActionType } from '../context/form-state-reducer';
import { NumberField as NumberFieldType } from '../../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { Typography } from '@material-ui/core';

interface Props {
  field: NumberFieldType;
}

const NumberField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  useEffect(() => {
    function validateField(): string {
      const { minValue: min, maxValue: max, required } = field.config;
      if (min && field.value < min) return t('value must be greater than {{min}}', { min });
      if (max && field.value > max) return t('value must be less than {{max}}', { max });
      if (!field.value && required) return t('Cannot be empty');
      return '';
    }

    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch]);

  function handleChange(e: any) {
    const re = /^[0-9\b]+$/;
    //check input against numbers only regex
    if (e.target.value === '' || re.test(e.target.value)) {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: e.target.value,
        },
      });
    }
  }

  return (
    <TextField
      fullWidth
      type={field.config.mask ? 'password' : 'text'}
      onChange={handleChange}
      key={field.id + '_field'}
      variant="outlined"
      size="small"
      label={
        <Typography
          variant="body1"
          style={{ textOverflow: 'ellipsis' }}
          className="whitespace-nowrap overflow-hidden"
        >
          {`${field.config.title} ${field.config.required ? '*' : ''}`}
        </Typography>
      }
      value={field.value}
      disabled={field.config.readonly}
      error={!!fieldError}
      helperText={fieldError}
      inputProps={{
        maxLength: 255,
      }}
    />
  );
};

export default NumberField;
