import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useFormData } from './mfp/context/FormDataContext';

export const ShowState = () => {
  const { state: formState } = useFormData();
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        style={{ position: 'absolute', top: '0', left: '0' }}
        variant="contained"
        onClick={() => setShow((value) => !value)}
      >
        ShowState
      </Button>
      {show && (
        <div
          onDrag={(e) => console.log('dragging', e.movementX, e.movementY)}
          id="debug"
          style={{
            fontSize: '12px',
            zIndex: '99',
            resize: 'horizontal',
            position: 'absolute',
            height: '95vh',
            width: '25%',
            overflow: 'scroll',
            left: '0',
            top: '5%',
            color: 'red',
            background: 'beige',
          }}
        >
          <pre>
            {/* <code>{JSON.stringify(state?.node?.config?.keywords, null, 2)}</code> */}
            <code>{JSON.stringify(formState, null, 2)}</code>
          </pre>
        </div>
      )}
    </>
  );
};
