import { FC } from 'react';
import { FormField, FormFieldTypeEnum } from '../../../interfaces/FormFieldTypes';
import GeneralTextField from './FormTextField';
import NumberField from './NumberField';
import PasswordField from './PasswordField';
import TextAreaField from './TextArea';
import HelperTooltip from '../HelperTooltip';
import { Grid } from '@material-ui/core';
import LabelField from './LabelField';
import CheckboxField from './CheckboxField';
import DropdownField from './DropdownField';
import DateTimeField from './DateTimeField';
import PageBreakField from './PageBreakField';

interface FieldProps {
  field: FormField;
}

const Field: FC<any> = (props: FieldProps) => {
  switch (props.field.type) {
    case FormFieldTypeEnum.text:
      return <GeneralTextField field={props.field} />;
    case FormFieldTypeEnum.number:
      return <NumberField field={props.field} />;
    case FormFieldTypeEnum.textarea:
      return <TextAreaField field={props.field} />;
    case FormFieldTypeEnum.password:
      return <PasswordField field={props.field} />;
    case FormFieldTypeEnum.label:
      return <LabelField field={props.field} />;
    case FormFieldTypeEnum.checkbox:
      return <CheckboxField field={props.field} />;
    case FormFieldTypeEnum.dropdown:
      return <DropdownField field={props.field} />;
    case FormFieldTypeEnum.datetime:
      return <DateTimeField field={props.field} />;
    case FormFieldTypeEnum.pageBreak:
      return <PageBreakField field={props.field} />;
    default:
      return <></>;
  }
};

const FieldRow: FC<any> = (props: FieldProps) => {
  if (!props.field.visible) return <></>;
  if (props.field.type === FormFieldTypeEnum.pageBreak) {
    return (
      <Grid container item xs={12} key={props.field.id + `_fragment`}>
        <Field field={props.field} />
      </Grid>
    );
  }
  return (
    <Grid container item xs={12} key={props.field.id + `_fragment`}>
      <Grid item xs={11}>
        <Field field={props.field} />
      </Grid>
      {props.field.type !== FormFieldTypeEnum.label && props.field.config.showHelp && (
        <Grid item xs={1}>
          <div className={props.field.type === FormFieldTypeEnum.checkbox ? 'flex h-full' : ''}>
            <HelperTooltip message={props.field.config.helpText} tool={props.field.config.title} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default FieldRow;
