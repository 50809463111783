import { Button } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { MessageBoxContext, MessageBoxStateActions } from '@dispatcher-stratus/stratus-react';
import { useContext } from 'react';

type Props = {
  message: string;
  tool: string;
};

const HelperTooltip = (props: Props) => {
  const { dispatch } = useContext(MessageBoxContext);
  return (
    <>
      <Button
        style={{
          alignSelf: 'center',
        }}
        onClick={() => {
          dispatch({
            type: MessageBoxStateActions.MESSAGE_BOX,
            payload: {
              open: true,
              title: props.tool,
              message: props.message,
            },
          });
        }}
      >
        <HelpOutlineIcon color="primary" />
      </Button>
    </>
  );
};

export default HelperTooltip;
