import { createTheme } from '@material-ui/core/styles';
import { TreeViewClassKey } from '@material-ui/lab/TreeView';
import { TreeItemClassKey } from '@material-ui/lab/TreeItem';
import * as _styles from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import type {} from '@material-ui/lab/themeAugmentation';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiTreeView: TreeViewClassKey;
    MuiTreeItem: TreeItemClassKey;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#007DA8',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#C8C8C8',
      contrastText: '#000',
      dark: '#3d3d3d',
    },
    action: {
      selectedOpacity: 0.5,
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 0, // square corners
      textTransform: 'none', // removes uppercase transformation
      fontWeight: 600,
    },
  },
  MuiAppBar: {
    root: {
      padding: '0px',
    },
    colorPrimary: { backgroundColor: theme.palette.secondary.dark },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  MuiTreeItem: {
    root: {
      '&:focus > $content $label': {
        backgroundColor: theme.palette.action.hover,
      },
      '&$selected > $content $label': {
        backgroundColor: _styles.alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
        backgroundColor: _styles.alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: _styles.alpha(
            theme.palette.secondary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  MuiFab: {
    primary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  MuiInputBase: {
    root: {
      fontSize: 'inherit',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '9px 9px',
    },
    root: {
      '&$focused .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha('#000000', 0.87),
      },
      '& fieldset p': {
        fontSize: '12px !important',
        lineHeight: '1.2em',
        textOverflow: 'unset',
      },
    },
  },
  MuiInputLabel: {
    root: {
      width: '100%',
      paddingRight: '2em',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '36px',
    },
  },
  MuiCssBaseline: {
    '@global': {
      '::-webkit-scrollbar': {
        width: '36px',
        height: '36px',
        border: '5px solid white',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#007DA8',
        backgroundClip: 'padding-box',
        border: '0.05em solid #FFF',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#FFF',
      },
      '::-webkit-scrollbar-button:single-button': {
        backgroundColor: '#FFF',
        display: 'block',
        borderStyle: 'solid',
        height: '30px',
        width: '36px',
      },
      '::-webkit-scrollbar-button:single-button:vertical:decrement': {
        borderWidth: '0 18px 18px 18px',
        borderColor: 'transparent transparent #007DA8 transparent',
      },
      '::-webkit-scrollbar-button:single-button:vertical:decrement:hover': {
        borderColor: 'transparent transparent #007DA8 transparent',
      },
      '::-webkit-scrollbar-button:single-button:vertical:increment': {
        borderWidth: '18px 18px 0 18px',
        borderColor: '#007DA8 transparent transparent transparent',
      },
      '::-webkit-scrollbar-button:vertical:single-button:increment:hover': {
        borderColor: '#007DA8 transparent transparent transparent',
      },
      '::-webkit-scrollbar-button:single-button:horizontal:decrement': {
        borderWidth: '18px 18px 18px 0',
        borderColor: 'transparent #007DA8 transparent transparent',
      },
      '::-webkit-scrollbar-button:single-button:horizontal:decrement:hover': {
        borderColor: 'transparent #007DA8 transparent transparent',
      },
      '::-webkit-scrollbar-button:single-button:horizontal:increment': {
        borderWidth: '18px 0 18px 18px',
        borderColor: 'transparent transparent transparent #007DA8',
      },
      '::-webkit-scrollbar-button:single-button:horizontal:increment:hover': {
        borderColor: 'transparent transparent transparent #007DA8',
      },
      '.mfp::-webkit-scrollbar': {
        width: 7,
        height: 7,
      },
      '.mfp::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0,0.0)',
        backgroundColor: '#3d3d3d',
      },
      '.mfp::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        borderRadius: '4px',
        borderWidth: '0',
      },
      '.mfp::-webkit-scrollbar-button': {
        borderWidth: 'unset',
        borderColor: 'unset',
        width: 0,
      },
    },
  },
  MuiCollapse: {
    wrapperInner: {
      width: '100%',
    },
  },
  MuiBreadcrumbs: {
    root: { color: 'white !important' },
    li: {
      '& button': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiDialog: {
    paper: {
      minWidth: '300px',
      '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        margin: 0,
        paddingLeft: '23px',
        paddingRight: '23px',
        paddingTop: '4px',
        paddingBottom: '4px',
        '& .MuiTypography-root': {
          '& .MuiTypography-body1': {
            fontSize: '16px',
            fontWeight: 700,
          },
        },
      },
      '& .MuiDialogContent-root': {
        padding: '23px',
        paddingBottom: '23px',
        '& .MuiDialogContent-text': {
          margin: '7px 0 7px 0',
        },
        '& .MuiTypography-root': {
          color: 'black',
          fontSize: '14px',
          margin: '0',
        },
      },
      '& .MuiDialogActions-root': {
        paddingTop: '0px',
        paddingRight: '23px',
        paddingBottom: '23px',
      },
    },
  },
  MuiTooltip: {
    tooltipPlacementBottom: {
      marginBottom: '5px',
      marginTop: '5px',
      backgroundColor: 'rgba(0, 87, 117, 0.87)',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      color: '#FFFFFF',
    },
  },
  MuiAutocomplete: {
    option: {
      '&[aria-selected=true]': {
        backgroundColor: 'unset',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  },
};

export default theme;
