import { useContext, useState } from 'react';
import Spinner from '../Spinner';
import { ActionBar } from './actionBar';
import { Body } from './body';
import Header from './Header';
import { NodeContext, NodeContextType } from '@dispatcher-stratus/stratus-react';
import { ShowState } from '../ShowState';
import PageNumberChip from './PageNumberChip';

export declare interface Props {
  children?: React.ReactNode; // best, accepts everything React can render
  next?: string;
  back?: string;
}

export const Layout = (props: Props): JSX.Element => {
  const { state } = useContext<NodeContextType>(NodeContext);
  const [count, setCount] = useState(0);
  return (
    <>
      <div className="bg-white h-screen flex flex-col ">
        <Spinner />
        <Header
          className="h-50px bg-mfpHeader px-5 flex-shrink-0"
          user={state.username}
          tenant={state.tenant?.name}
        ></Header>
        <ActionBar className="h-56px bg-mfpActionBar px-2.5 py-2 flex-shrink-0 pl-4"></ActionBar>
        <Body className="justify-center overflow-x-auto h-full overflow-y-auto">
          {props.children}
        </Body>
        <div
          className="absolute bottom-1 right-10"
          onClick={() => setCount((current) => (current < 5 ? ++current : current))}
        >
          <PageNumberChip />
        </div>
        <div>{count === 5 && <ShowState />}</div>
      </div>
    </>
  );
};
