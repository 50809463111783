import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Layout } from './layout';
import Form from './Form';
import { useProcessData } from './hooks/useProcessData';

export const Mfp = () => {
  let { path } = useRouteMatch();
  const { isLoading } = useProcessData();
  return (
    <Switch>
      <Route path={`${path}`}>
        <Layout>{isLoading ? <></> : <Form />}</Layout>
      </Route>
    </Switch>
  );
};
