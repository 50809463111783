import { Button, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@dispatcher-stratus/stratus-react/dist/assets/home.svg';
import NextIcon from '@dispatcher-stratus/stratus-react/dist/assets/next.svg';
import RefreshIcon from '@dispatcher-stratus/stratus-react/dist/assets/refresh.svg';
import { useFormData } from './context/FormDataContext';
import { FormActionType } from './context/form-state-reducer';
import { FormFieldTypeEnum, PageBreakField } from '../../interfaces/FormFieldTypes';
import { submitForm } from '../../lib/formApi';
import { useQueryData } from './hooks/useQueryData';
import { MessageBoxContext, MessageBoxStateActions } from '@dispatcher-stratus/stratus-react';
import { useProcessData } from './hooks/useProcessData';

export declare interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode; // best, accepts everything React can render
}

export const ActionBar = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { state: formState, dispatch: formDispatch } = useFormData();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const { sessionID, returnToken, returnURL, tenant } = useQueryData();
  const { data: processData } = useProcessData();
  const [currentPageBreak, setCurrentPageBreak] = useState(
    formState.formDefinition.fields.filter((field) => field.type === FormFieldTypeEnum.pageBreak)[
      formState.currentPage.number - 1
    ] as PageBreakField,
  );
  useEffect(() => {
    setCurrentPageBreak(
      () =>
        formState.formDefinition.fields.filter(
          (field) => field.type === FormFieldTypeEnum.pageBreak,
        )[formState.currentPage.number - 1] as PageBreakField,
    );
  }, [formState]);
  return (
    <div {...props} className={props.className + ' w-full flex justify-between items-center'}>
      <div className="flex h-40px items-center w-full justify-between pr-2">
        <Typography variant="h6" color="textPrimary" style={{ fontSize: '22px' }}>
          {`${formState.currentPage.config.title}`}
        </Typography>
      </div>
      <div className="flex h-40px space-x-2.5">
        <Button
          style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
          variant="contained"
          color="secondary"
          onClick={() => {
            window.location.href = `https://mfp.${process.env.REACT_APP_DOMAIN}`;
          }}
          disabled={currentPageBreak?.config?.hideHome}
        >
          <img src={HomeIcon} alt={t('Home')} />
        </Button>
        <Button
          style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
          variant="contained"
          color="secondary"
          onClick={() => {
            formDispatch({ type: FormActionType.RESET_FORM });
          }}
        >
          <img src={RefreshIcon} alt={t('Refresh')} />
        </Button>
        <Button
          style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
          variant="contained"
          color="secondary"
          disabled={formState.currentPage.number === 1 || currentPageBreak?.config?.hideBack}
          onClick={() => {
            formDispatch({
              type: FormActionType.DECREMENT_PAGE,
            });
          }}
        >
          <img style={{ transform: 'rotate(180deg)' }} src={NextIcon} alt={t('Back')} />
        </Button>
        <Button
          style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
          variant="contained"
          color={
            formState.valid && formState.currentPage.number === formState.numPages
              ? 'primary'
              : 'secondary'
          }
          onClick={() => {
            if (formState.currentPage.number < formState.numPages) {
              formDispatch({
                type: FormActionType.INCREMENT_PAGE,
              });
            } else {
              if (!formState.valid) return;
              submitForm(formState.formDefinition.fields, {
                url: returnURL,
                token: returnToken,
                sessionID,
                fileID: processData.processData.data.files,
                region: tenant.region,
              }).catch((err) =>
                dispatchMessage({
                  type: MessageBoxStateActions.MESSAGE_BOX,
                  payload: {
                    open: true,
                    title: t('Form Submission Error'),
                    message: err.message,
                  },
                }),
              );
            }
          }}
          disabled={
            (formState.currentPage.number === formState.numPages && !formState.valid) ||
            currentPageBreak?.config?.hideNext
          }
        >
          <img src={NextIcon} alt={t('Next')} />
        </Button>
      </div>
    </div>
  );
};
