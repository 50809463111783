import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

const dialogStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    paper: {
      backgroundColor: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100',
      height: '100',
      boxShadow: '0 0',
    },
  }),
);

export interface DialogProps {
  open: boolean;
}

const Spinner = () => {
  const style = dialogStyle();
  const { promiseInProgress } = usePromiseTracker(); //TODO: fix this causing findDOMNode is deprecated in StrictMode warning
  const theme = useTheme();

  return (
    <Dialog open={promiseInProgress} classes={style}>
      <ThreeDots color={theme.palette.primary.main} height="100" width="100" />
    </Dialog>
  );
};

export default Spinner;
