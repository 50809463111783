import Loading from './components/Loading';
import App from './App';
import { Suspense } from 'react';

const LoadableDashboard = () => {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
};

export default LoadableDashboard;
