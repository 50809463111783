import { FormField, PageBreakFieldConfig } from './FormFieldTypes';
export interface FormDataType {
  id: string;
  title: string;
  creator: string;
  editor: string;
  created: number;
  updated: number;
  formStatus: string;
  formGroup: { id: string };
  valid: boolean;
  height: number;
  width: number;
  formDefinition: {
    targetMfpId: string;
    fields: FormField[];
  };
  currentPage: {
    number: number;
    config: Omit<
      PageBreakFieldConfig,
      'variable' | 'showHelp' | 'excludeFromMetadata' | 'helpText'
    >;
  };

  numPages: number;
  errors: Map<string, string>;
  loaded: boolean;
}

export const INITIAL_FORM_CONFIG: FormDataType = {
  id: '',
  title: '',
  creator: '',
  editor: '',
  created: 0,
  updated: 0,
  width: window.innerWidth,
  height: window.innerHeight,
  formStatus: 'published',
  formGroup: { id: '' },
  valid: false,
  formDefinition: {
    targetMfpId: 'none',
    fields: [],
  },
  currentPage: {
    number: 1,
    config: {
      hideBack: false,
      hideNext: false,
      hideHome: false,
      hidePageNumbers: false,
      hideReturn: false,
      hideScan: false,
      title: '',
    },
  },
  numPages: 1,
  errors: new Map<string, string>(),
  loaded: false,
};
