import { trackPromise } from 'react-promise-tracker';
import axios from './axios';
import { compileFormData, massageFormInput } from '../utils/formUtils';
import { getMetadata } from '@dispatcher-stratus/metadata';
import { FormField } from '../interfaces/FormFieldTypes';

const getForm = async (fid: string, slug: string, region: string, token: string) => {
  const response = await trackPromise(
    axios.get(
      `https://${region ?? 'us-east-1'}.forms-service.${
        process.env.REACT_APP_DOMAIN ?? 'stratus.lol'
      }/api/forms/${fid}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'stc-tenant-slug': slug,
        },
        withCredentials: false,
      },
    ),
  );
  if (response.data.code) {
    console.error(response.data);
    throw new Error(response.data);
  }
  return response.data;
};

export async function initializeForm(
  formID: string,
  sessionID: string,
  workflowID: string,
  userID: string,
  fileID: string,
  tenant: { slug: string; region: string },
  token: string,
) {
  const rawForm = await getForm(formID, tenant.slug, tenant.region, token);
  if (!rawForm) return;
  if (
    !rawForm.valid ||
    (rawForm.formStatus !== 'published' && process.env.NODE_ENV !== 'development')
  ) {
    throw new Error('Please make sure to validate and publish the form before accessing it.');
  }
  let metadata = { environment: new Map<any, any>(), records: new Map<string, any>() };
  metadata = await getMetadata({
    workflowId: workflowID,
    userId: userID,
    processId: sessionID,
    fileId: fileID,
  });
  return await massageFormInput(rawForm, metadata);
}

export async function submitForm(
  formFields: FormField[],
  {
    url,
    token,
    sessionID,
    fileID,
    region,
  }: { url: string; token: string; sessionID: string; fileID: string; region: string },
) {
  const formdata = compileFormData(formFields);
  console.log(formdata);
  const fileData = await trackPromise(
    axios.get(
      `https://${region ?? 'us-east-1'}.metadata-api.${
        process.env.REACT_APP_DOMAIN ?? 'stratus.lol'
      }/api/v1/file/${sessionID}/${encodeURIComponent(fileID)}`,
    ),
  );

  const filePayload = {
    data: { ...fileData.data.data, form: { ...fileData.data.data.form, ...formdata } },
  };
  console.log('file payload', { filePayload });

  const putFileData = await trackPromise(
    axios.put(
      `https://${region ?? 'us-east-1'}.metadata-api.${
        process.env.REACT_APP_DOMAIN ?? 'stratus.lol'
      }/api/v1/file/${sessionID}/${fileID}`,
      filePayload,
    ),
  );

  if (putFileData.status > 300) {
    console.log(putFileData);
    throw new Error(putFileData.data.message);
  }

  console.log(putFileData);

  // const checkfileData = await axios.get(
  //   `https://${region ?? 'us-east-1'}.metadata-api.${
  //     process.env.REACT_APP_DOMAIN ?? 'stratus.lol'
  //   }/api/v1/file/${sessionID}/${encodeURIComponent(fileID)}`,
  // );

  // console.log('checkfile', checkfileData.data);

  await sendReport(url, token);

  window.location.href = `https://mfp.${process.env.REACT_APP_DOMAIN}/processing?arn=${sessionID}`;
}

export async function sendReport(url: string, token: string) {
  const body = {
    token,
    success: true,
    response: '',
  };
  try {
    const response = await trackPromise(axios.post(url, body));
    if (response.status < 300) return response;
    throw new Error(response.data);
  } catch (err: any) {
    throw new Error(err);
  }
}
