import { FC, useContext, useEffect } from 'react';
import { useFormData } from './context/FormDataContext';
import Field from './form-fields/Field';
import { Grid } from '@material-ui/core';
import { trackPromise } from 'react-promise-tracker';
import { MessageBoxContext, MessageBoxStateActions } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { useQueryData } from './hooks/useQueryData';
import { useProcessData } from './hooks/useProcessData';
import { FormActionType } from './context/form-state-reducer';
import { initializeForm } from '../../lib/formApi';

export const Form: FC = () => {
  const { state: formState, dispatch: formDispatch } = useFormData();
  const { token, tenant, workflowID, userID, sessionID } = useQueryData();
  const { data: processData } = useProcessData();
  const { dispatch: showMessage } = useContext(MessageBoxContext);

  useEffect(() => {
    if (formState.loaded) return;
    if (!!!sessionID || !!!workflowID || !!!token) {
      return showMessage({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          open: true,
          title: t('Invalid Configuration'),
          message: `Configuration validables are invalid`,
        },
      });
    }
    console.log(processData);
    trackPromise(
      initializeForm(
        processData.nodeConfig.config.formId,
        sessionID,
        workflowID,
        userID,
        processData.processData.data.files,
        tenant,
        token,
      ).then((form) => {
        if (!form) return;
        formDispatch({
          type: FormActionType.POPULATE_FORM,
          payload: form,
        });
      }),
    ).catch((err) => {
      console.error(err);
      return showMessage({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          open: true,
          title: t('Form Error'),
          message: err.message,
        },
      });
    });
  }, [
    formState.loaded,
    showMessage,
    sessionID,
    workflowID,
    userID,
    formDispatch,
    processData,
    tenant,
    token,
  ]);

  if (!formState.loaded) return <></>;

  /** Render */
  return (
    <div className="text-xl w-full px-10 pt-5">
      <Grid container direction="column" alignItems="center" spacing={5}>
        {formState.formDefinition.fields
          .filter((field) => field.page === formState.currentPage.number)
          .map((field) => (
            <Field key={field.id + '_field'} field={field} />
          ))}
      </Grid>
    </div>
  );
};

export default Form;
