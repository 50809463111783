import moment from 'moment';
import { FormField, FormFieldTypeEnum, PageBreakField } from '../interfaces/FormFieldTypes';
import { FormDataType, INITIAL_FORM_CONFIG } from '../interfaces';
import { expandString } from '@dispatcher-stratus/metadata';

const visibleFields = [
  FormFieldTypeEnum.text,
  FormFieldTypeEnum.textarea,
  FormFieldTypeEnum.password,
  FormFieldTypeEnum.checkbox,
  FormFieldTypeEnum.datetime,
  FormFieldTypeEnum.dropdown,
  FormFieldTypeEnum.label,
  FormFieldTypeEnum.number,
  FormFieldTypeEnum.password,
];

export function compileFormData(formFields: FormField[]) {
  const filteredFields = formFields.filter(
    (field) =>
      typeof field.config.excludeFromMetadata !== 'undefined' && !field.config.excludeFromMetadata,
  );
  const exportFields: any = {};
  filteredFields.forEach((field) => {
    const massagedField = massageFormFieldExport(field);
    exportFields[massagedField.config.variable] = massagedField.value;
  });
  return exportFields;
}

export function massageFormFieldImport(
  field: FormField,
  metadata: { environment: Map<any, any>; records: Map<string, any> },
) {
  let newField = JSON.parse(JSON.stringify(field));
  switch (field.type) {
    case FormFieldTypeEnum.dropdown:
      newField.config.options = field.config.options
        .filter((option) => option.show)
        .map((option) => ({
          ...option,
          value: expandString(option.value, metadata),
          label: expandString(option.label, metadata),
        }));
      newField.value = [
        newField.config.options.filter((option: { isDefault: boolean }) => option.isDefault).value,
      ];
      break;

    case FormFieldTypeEnum.number:
      newField.value = +newField.defaultValue || '';
      newField.config.minValue = +newField.config.minValue;
      newField.config.maxValue = +newField.config.maxValue;
      break;

    case FormFieldTypeEnum.datetime:
      const { defaultToNow, defaultValue, minValue, maxValue } = field.config;
      if (defaultValue) newField.value = moment(defaultValue);
      if (defaultToNow) newField.value = moment();
      if (minValue) newField.config.minValue = moment(minValue);
      if (maxValue) newField.config.maxValue = moment(maxValue);
      break;

    case FormFieldTypeEnum.checkbox:
      newField.value = field.config.checked;
      newField.config.trueLabel = expandString(field.config.trueLabel, metadata);
      newField.config.falseLabel = expandString(field.config.falseLabel, metadata);
      break;
    default:
      //TODO: figure out how to fix this type issue
      //@ts-ignore
      newField.value = expandString(field.config?.defaultValue || '', metadata);
  }
  newField.visible = visibleFields.includes(field.type);

  return newField;
}

export function massageFormFieldExport(field: FormField) {
  const massagedField: FormField = JSON.parse(JSON.stringify(field));
  switch (field.type) {
    case FormFieldTypeEnum.dropdown:
      massagedField.value = field.value.join(', ');
      break;

    case FormFieldTypeEnum.datetime:
      massagedField.value = field.value ? field.value.format(field.config.returnFormat) : '';
      break;
  }

  return massagedField;
}

export function parseTargetDimensions(mfpId: string) {
  const regex = /\d{3,4}x\d{3,4}/;
  const matches = mfpId.match(regex);
  if (!matches || matches.length === 0) {
    console.error('FAILED TO EXTRACT FORM DIMENSIONS FROM ', mfpId);
    //fallback to screen size
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }
  const [width, height] = matches[0].split('x');
  return { height: Number.parseInt(height), width: Number.parseInt(width) };
}

export function massageFormInput(
  payload: any,
  metadata: { environment: Map<any, any>; records: Map<string, any> },
) {
  return new Promise<FormDataType>((resolve, reject) => {
    const formDimensions = parseTargetDimensions(payload.formDefinition.targetMfpId);
    let newForm = INITIAL_FORM_CONFIG;
    newForm.id = payload.id;
    newForm.width = formDimensions.width;
    newForm.height = formDimensions.height;
    newForm.title = payload.title;
    newForm.creator = payload.creator;
    newForm.editor = payload.editor;
    newForm.created = payload.created;
    newForm.updated = payload.updated;
    newForm.formStatus = payload.formStatus;
    newForm.formGroup = payload.formGroup;
    newForm.valid = true;
    newForm.formDefinition = JSON.parse(JSON.stringify(payload.formDefinition));
    let page: number = 0;
    newForm.formDefinition.fields = payload.formDefinition.fields.map((field: any) => {
      field = massageFormFieldImport(field, metadata);
      if (field.type === FormFieldTypeEnum.pageBreak) {
        page++;
        if (field.config.showHelp) field.visible = true;
      }
      field.page = page;
      return field;
    });
    newForm.currentPage = {
      config: (newForm.formDefinition.fields[0] as PageBreakField).config,
      number: 1,
    };
    newForm.currentPage.config.title = newForm.currentPage.config.title || newForm.title;
    newForm.numPages = page;
    newForm.loaded = true;
    resolve(newForm);
  });
}
