import { useFormData } from '../context/FormDataContext';
import { DateTimeField as DateTimeFieldType } from '../../../interfaces/FormFieldTypes';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid, TextField, Typography } from '@material-ui/core';
import { FormActionType } from '../context/form-state-reducer';
import { useEffect } from 'react';
import { t } from 'i18next';
import moment from 'moment';

type Props = {
  field: DateTimeFieldType;
};

const DateTimeField = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!field.value && field.config.defaultValue)
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: field.config.defaultValue,
        },
      });

    function validateField(): string {
      const { required } = field.config;
      if (!field.value) {
        if (required) return t('Cannot be empty');
      } else {
        if (field.config.maxValue && field.value.isAfter(field.config.maxValue))
          return t('Date and Time must be before {{max}}', {
            max: field.config.maxValue.toLocaleString(),
          });
        if (field.config.minValue && field.value.isBefore(field.config.minValue))
          return t('Date and Time must be after {{min}}', {
            min: field.config.minValue.toLocaleString(),
          });
      }
      return '';
    }
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });

    return () => clearInterval(interval);
  }, [field, dispatch]);

  const onDateSelect = (e: any) => {
    const date = moment(e.target.value);
    if (date.isValid()) {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: date,
        },
      });
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TextField
          fullWidth
          type="datetime-local"
          key={field.id + '_field'}
          variant="outlined"
          size="small"
          defaultValue={field.value?.format().slice(0, -6)}
          label={
            <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
              {field.config.title}
              {field.config.required ? ' *' : ''}
            </Typography>
          }
          error={!!fieldError}
          disabled={field.config.readonly}
          onChange={onDateSelect}
          helperText={fieldError}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: field.config.minValue ? field.config.minValue.format().slice(0, -6) : undefined,
            max: field.config.maxValue ? field.config.maxValue.format().slice(0, -6) : undefined,
          }}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="date"
            key={field.id + '_field'}
            variant="outlined"
            size="small"
            defaultValue={field.value?.format().slice(0, -6)}
            label={
              <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
                {field.config.title}
                {field.config.required ? ' *' : ''}
              </Typography>
            }
            error={!!fieldError}
            disabled={field.config.readonly}
            helperText={fieldError}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: field.config.minValue ? field.config.minValue.format().slice(0, -6) : undefined,
              max: field.config.maxValue ? field.config.maxValue.format().slice(0, -6) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="time"
            key={field.id + '_field'}
            variant="outlined"
            size="small"
            defaultValue={field.value?.format().slice(0, -6)}
            label={
              <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
                {field.config.title}
                {field.config.required ? ' *' : ''}
              </Typography>
            }
            error={!!fieldError}
            disabled={field.config.readonly}
            helperText={fieldError}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: field.config.minValue ? field.config.minValue.format().slice(0, -6) : undefined,
              max: field.config.maxValue ? field.config.maxValue.format().slice(0, -6) : undefined,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DateTimeField;
