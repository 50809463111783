import { CssBaseline } from '@material-ui/core';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Landing from './Landing';
import themeMfp from './components/mfp/theme';
import {
  MessageBoxContextProvider,
  CustomMessageBox,
  DisableContextAndShortcuts,
} from '@dispatcher-stratus/stratus-react';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Mfp } from './components/mfp';
import { FormDataContextProvider } from './components/mfp/context/FormDataContext';
import './__mocks__/form';
import './__mocks__/session';

function App() {
  const queryClient = new QueryClient();
  const routing = (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>
          <Route path="/client/mfp">
            <ThemeProvider theme={themeMfp}>
              <CssBaseline />
              <MessageBoxContextProvider>
                <FormDataContextProvider>
                  <Mfp />
                  <CustomMessageBox i18n={i18n}></CustomMessageBox>
                </FormDataContextProvider>
              </MessageBoxContextProvider>
            </ThemeProvider>
          </Route>
          <Route path="/client">
            <Landing></Landing>
          </Route>
          <Redirect from="/" to="/client" />
        </Switch>
      </Router>
    </I18nextProvider>
  );
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider jss={jss}>
        <DisableContextAndShortcuts />
        <div className="App flex flex-col h-screen v-screen bg-black">{routing}</div>
      </StylesProvider>
    </QueryClientProvider>
  );
}
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')!,
});
export default App;
