import { makeStyles } from '@material-ui/core/styles'

import loadingIcon from '../../assets/loading.gif'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.5em',
      fontWeight: '400'
    }
  }
})

const Loading = () => {
  const classes = useStyles()
  const { t } = useTranslation('translation', i18n)
  return (
    <div className={classes.loading}>
      <img src={loadingIcon} alt='Loading...' />
      <h1>{t('Loading')}...</h1>
    </div>
  )
}

export default Loading
