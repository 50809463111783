import { axiosMockAdapterInstance as mock } from '../lib/axios';

console.log('mocking form...');

mock.onGet('api/forms/1').reply(() => {
  const form = {
    id: '31401327af274e8fbc475eee2e4d9189',
    title: 'Full Form Example',
    creator: 'user 1',
    editor: 'user 1',
    created: 1690810009418,
    updated: 1690810009418,
    formStatus: 'published',
    valid: true,
    formGroup: {
      id: '00000',
    },
    formDefinition: {
      targetMfpId: 'mfp_800x480',
      fields: [
        {
          type: 'pagebreak',
          id: 'vn01PYmrf6L-o0O9-yDD8',
          config: {
            variable: 'page1',
            title: 'My First Page',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: true,
            helpText: 'hello world',
          },
        },
        {
          type: 'text',
          id: 'lO5CFI-2cUnyK4Y4bPWC0',
          config: {
            variable: 'text1',
            title: 'Text Line field friendly name goes here',
            defaultValue: 'Hello',
            minLength: '1',
            maxLength: '50',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'textarea',
          id: '5AJqeHry7VHJ3XTnmMhDc',
          config: {
            variable: 'textarea1',
            title: 'Text Area field friendly name goes here',
            defaultValue: 'World',
            minLength: '1',
            maxLength: '100',
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'number',
          id: 'MF-bG6wUOYhBqeYR3yrKk',
          config: {
            variable: 'number1',
            title: 'Number field friendly name goes here',
            defaultValue: '1234',
            minLength: '1',
            maxLength: '7',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'password',
          id: 'mXQkf-dh1PlxzKIX6sEKl',
          config: {
            variable: 'password1',
            title: 'Password field friendly name goes here',
            minLength: '',
            maxLength: '',
            required: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'checkbox',
          id: 'sJ-N9t2hnspIO08w9RI83',
          config: {
            variable: 'checkbox1',
            title: 'Checkmark field friendly name goes here',
            defaultValue: '',
            trueLabel: 'Yes',
            falseLabel: 'No',
            readonly: false,
            checked: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'dropdown',
          id: 'FGhLBdcvv4xRq9fZQ0wVF',
          config: {
            variable: 'dropdown1',
            title: 'Dropdown field friendly name goes here',
            defaultValue: '',
            minLength: '',
            maxLength: '',
            required: true,
            readonly: false,
            multiSelect: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
            options: [
              {
                id: '5oOhAtDIj1A6P6U8UWGfY',
                label: 'User1',
                value: 'user1@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: '12l_4npZrrNVbBIL3cHv8',
                label: 'User2',
                value: 'user2@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: 'SCwAEMFdeM6vrsr73DNdN',
                label: 'User3',
                value: 'user3@domain.com',
                show: true,
                isDefault: true,
              },
            ],
          },
        },
        {
          type: 'date',
          id: 'h3ew-M3oAOxbcg7ji-_M-',
          config: {
            variable: 'date1',
            title: 'Date and Time field friendly name',
            defaultValue: '',
            minValue: '08/01/2023 10:00 am',
            maxValue: '09/05/2023 12:30 pm',
            returnFormat: 'MMMM Do YYYY, h:mm:ss a',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'label',
          id: 'fRT-OO3Kw5Y1dRsT0fHCC',
          config: {
            variable: 'label1',
            title: 'Label goes here',
          },
        },
        {
          type: 'hidden',
          id: 'uoXzu4Vs2Z7wJHHYUvE4c',
          config: {
            variable: 'hidden1',
            defaultValue: '3232',
            excludeFromMetadata: false,
          },
        },
        {
          type: 'pagebreak',
          id: '3oy5KXuT_0o6Uf4q2UpTP',
          config: {
            variable: 'page2',
            title: 'My Second Page ',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: true,
            helpText:
              'really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text rea',
          },
        },
        {
          type: 'text',
          id: 'JOq-0uiIKB2LwMUpaRvIB',
          config: {
            variable: 'text2',
            title: 'Text Line field friendly name goes here',
            defaultValue: 'Hello',
            minLength: '1',
            maxLength: '50',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'textarea',
          id: 'uM6L8wNZYpD41mjYpYDCK',
          config: {
            variable: 'textarea2',
            title: 'Text Area field friendly name goes here',
            defaultValue: 'line 1 test here\nline 2 test here\nline 3 test here\nline 4 test here',
            minLength: '1',
            maxLength: '100',
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'number',
          id: 'wJXEz3-MdaKP4TUAeAIz8',
          config: {
            variable: 'number2',
            title: 'Number field friendly name goes here',
            defaultValue: '1234',
            minLength: '1',
            maxLength: '7',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'password',
          id: 'zD1L8JkvVE2orZ7BMJ_MQ',
          config: {
            variable: 'password2',
            title: 'Password field friendly name goes here',
            minLength: '',
            maxLength: '',
            required: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'checkbox',
          id: 'kpYTyQ7vB3pydm6dIWxoD',
          config: {
            variable: 'checkbox2',
            title: 'Checkmark field friendly name goes here',
            defaultValue: '',
            trueLabel: 'Yes',
            falseLabel: 'No',
            readonly: false,
            checked: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'dropdown',
          id: '_j7j5JO_i2A5B1ZK_vD2M',
          config: {
            variable: 'dropdown2',
            title: 'Dropdown field friendly name goes here',
            defaultValue: '',
            minLength: '',
            maxLength: '',
            required: true,
            readonly: false,
            multiSelect: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
            options: [
              {
                id: '5oOhAtDIj1A6P6U8UWGfY',
                label: 'User1',
                value: 'user1@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: '12l_4npZrrNVbBIL3cHv8',
                label: 'User2',
                value: 'user2@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: 'SCwAEMFdeM6vrsr73DNdN',
                label: 'User3',
                value: 'user3@domain.com',
                show: true,
                isDefault: false,
              },
            ],
          },
        },
        {
          type: 'date',
          id: 'DQqLuXZ_Xan1tkjyKv0tX',
          config: {
            variable: 'date2',
            title: 'Date and Time field friendly name goes here',
            defaultValue: '7/31/2023, 9:16:05 AM',
            minValue: '',
            maxValue: '',
            returnFormat: '',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'label',
          id: 'vYMQFYywY2DVzfUJZIjwL',
          config: {
            variable: 'label2',
            title: 'Label goes here',
          },
        },
        {
          type: 'hidden',
          id: '-bXzMX5FeGimBvdznhG7Q',
          config: {
            variable: 'hidden2',
            defaultValue: '4444',
            excludeFromMetadata: false,
          },
        },
      ],
    },
  };
  return [200, form];
});

mock.onGet('/api/node/sessions/123/session-123').reply(() => [200, {}]);
