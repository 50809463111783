import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryData = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const sessionID = queryParams.get('arn');
  const workflowID = queryParams.get('workflowId');
  const nodeID = queryParams.get('nodeId');
  const returnURL = queryParams.get('url');
  const returnToken = queryParams.get('token');
  const metadata = JSON.parse(decodeURIComponent(queryParams.get('metadata') || ''));
  const { tenant, token } = metadata;
  const userID = metadata?.userId;
  if (
    !sessionID ||
    !workflowID ||
    !nodeID ||
    !tenant ||
    !userID ||
    !token ||
    !returnURL ||
    !returnToken
  ) {
    throw Error('Missing Node Query Parameters');
  }

  return { sessionID, workflowID, nodeID, tenant, userID, token, returnURL, returnToken };
};
