import { axiosMockAdapterInstance as mock } from '../lib/axios';

console.log('mocking session...');

mock
  .onGet('/dev/workflow/node/download?workflowId=testworkflow&nodeId=123&arn=testprocess')
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '1' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });

mock
  .onGet('/api/v1/process/testworkflow/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
