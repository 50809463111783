import React, { createContext, useContext, useReducer } from 'react';
import { FormDataType, INITIAL_FORM_CONFIG } from '../../../interfaces';
import { FormActions } from './form-state-actions';
import { formStateReducer } from './form-state-reducer';

type FormContextType = {
  state: FormDataType;
  dispatch: (actions: FormActions) => void;
};

export const FormDataContext = createContext<FormContextType>({} as FormContextType);

export const FormDataContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [formState, formDispatch] = useReducer(formStateReducer, INITIAL_FORM_CONFIG);

  return (
    <FormDataContext.Provider value={{ state: formState, dispatch: formDispatch }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  const formDataContext = useContext(FormDataContext);
  if (!formDataContext)
    throw Error('Form Data Context not found. Use this hook with FormDataContextProvider.');
  return formDataContext;
};
