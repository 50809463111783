import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import STCLogo from '../../../assets/logo_scantrip_white.svg'
import Logout from '../../../assets/logout.svg'

const useStyles = makeStyles({
  controls: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '& *': {
      margin: '0'
    }
  },
  header: {
    background: '#323232',
    display: 'flex',
    color: 'white',
    height: 50,
    padding: 7,
    flexShrink: 0,
    justifyContent: 'space-between'
  },
  logo: {
    display: 'inline-flex'
  },
  text: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  text_holder: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  logout_text: {
    fontSize: 'medium',
    color: 'white'
  }
})

const Header = props => {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <img src={STCLogo} alt='Dispatcher ScanTrip Cloud' />
      </div>
      <IconButton
        style={{
          background: 'inherit',
          padding: '0px 0px',
          margin: '5px'
        }}
        onClick={() => {
          window.location.href = `https://mfp.${process.env.REACT_APP_DOMAIN}/logout`
        }}
      >
        <img src={Logout} alt='Logout' />
      </IconButton>
    </div>
  )
}

export default Header
