import { useQueryData } from './useQueryData';
import { trackPromise } from 'react-promise-tracker';
import axios from '../../../lib/axios';
import { useContext } from 'react';
import { MessageBoxContext, MessageBoxStateActions } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { useQuery } from 'react-query';

export const useProcessData = () => {
  const { workflowID, sessionID, nodeID, tenant } = useQueryData();
  const { dispatch: showMessage } = useContext(MessageBoxContext);
  const fetcher = (url: string) => trackPromise(axios.get(url)).then((res) => res.data);

  const getEnv = (env = 'stratus.lol', region = 'us-east-1'): string => {
    const URLS: any = {
      'stratus.lol': {
        'us-east-1': 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev',
        'eu-central-1': 'https://io59oayis1.execute-api.eu-central-1.amazonaws.com/dev',
        'ap-northeast-1': 'https://kt9ula9e5b.execute-api.ap-northeast-1.amazonaws.com/dev',
      },
      'dodgestrat.us': {
        'us-east-1': 'https://t09d4tj8oc.execute-api.us-east-1.amazonaws.com/beta',
        'eu-central-1': 'https://b6bkedjs92.execute-api.eu-central-1.amazonaws.com/beta',
        'ap-northeast-1': 'https://43jzx69z83.execute-api.ap-northeast-1.amazonaws.com/beta',
      },
      'scantripcloud.com': {
        'us-east-1': 'https://m41s6emkk9.execute-api.us-east-1.amazonaws.com/prod',
        'eu-central-1': 'https://mm67obwrd6.execute-api.eu-central-1.amazonaws.com/prod',
        'ap-northeast-1': 'https://nfp50jzorj.execute-api.ap-northeast-1.amazonaws.com/prod',
      },
    };
    return URLS?.[env]?.[region] || URLS['stratus.lol']['us-east-1'];
  };

  const wfxDomain = getEnv(process.env.REACT_APP_DOMAIN, tenant.region);

  const configQuery = useQuery(
    'nodeConfig',
    () =>
      fetcher(
        `${wfxDomain}/workflow/node/download?workflowId=${workflowID}&nodeId=${nodeID}&arn=${sessionID}`,
      ),
    { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false, retry: false },
  );

  const processQuery = useQuery(
    'processData',
    () =>
      fetcher(
        `https://${tenant.region ?? 'us-east-1'}.metadata-api.${
          process.env.REACT_APP_DOMAIN ?? 'stratus.lol'
        }/api/v1/process/${workflowID}/${sessionID}`,
      ),
    { staleTime: Infinity, refetchOnMount: false, refetchOnWindowFocus: false, retry: false },
  );

  if (configQuery.isError || processQuery.isError) {
    console.log(configQuery.error, processQuery.error);
    showMessage({
      type: MessageBoxStateActions.MESSAGE_BOX,
      payload: {
        open: true,
        title: t('Form Error'),
        message: t('Failed to retrieve process data.'),
      },
    });
  }

  return {
    data: { nodeConfig: configQuery.data, processData: processQuery.data },
    isLoading: configQuery.isLoading || processQuery.isLoading,
  };
};
