import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@dispatcher-stratus/stratus-react/dist/index.css';
import LoadableApp from './LoadableApp';
import reportWebVitals from './reportWebVitals';
import { enableMapSet } from 'immer';

ReactDOM.render(
  <React.StrictMode>
    <LoadableApp />
  </React.StrictMode>,
  document.getElementById('root'),
);

enableMapSet();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
